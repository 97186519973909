//PuzzlePage.tsx
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ChessboardComponent from './chessboard';
import { Grid, Typography, Container, Tabs, Tab, Button, Box, TextField } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ShareIcon from '@mui/icons-material/Share';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { TextFieldProps } from '@mui/material/TextField';
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/material/styles/useTheme';

interface PuzzleDetail {
  difficulty: string;
  result: string;
  first_move: string;
  fen: string;
  solved?: string;  // Optional 'solved' property
}

interface Puzzle {
  easy: PuzzleDetail;
  medium: PuzzleDetail;
  hard: PuzzleDetail;
}

const defaultPuzzle: Puzzle = {
  easy: { difficulty: 'easy', result: 'win', first_move: '', fen: '8/8/8/3k4/4K3/8/8/8 w - - 0 1' },
  medium: { difficulty: 'medium', result: 'win', first_move: '', fen: '8/8/8/4k3/4K3/8/8/8 w - - 0 1' },
  hard: { difficulty: 'hard', result: 'win', first_move: '', fen: '8/8/8/4k3/4K3/8/8/8 w - - 0 1' },
};

const PuzzlePage: React.FC = () => {
  const { date, difficulty } = useParams<{ date: string; difficulty: 'easy' | 'medium' | 'hard' }>();
  const navigate = useNavigate();
  const [puzzle, setPuzzle] = useState<Puzzle>(defaultPuzzle);
  const [loading, setLoading] = useState(true);
  const [selectedDifficulty, setSelectedDifficulty] = useState<'easy' | 'medium' | 'hard'>(difficulty || 'easy');
  const [selectedDate, setSelectedDate] = useState<Date | null>(date ? new Date(date) : new Date());

  useEffect(() => {
    const formattedDate = selectedDate ? selectedDate.toISOString().split('T')[0] : new Date().toISOString().split('T')[0];
    const fetchPuzzle = async () => {
      setLoading(true);
      const storedPuzzleKey = `puzzle-${formattedDate}`;
      try {
        const storedPuzzleData = localStorage.getItem(storedPuzzleKey);
        if (storedPuzzleData) {
          setPuzzle(JSON.parse(storedPuzzleData));
        } else {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/api/puzzles/${formattedDate}`);
          if (!response.ok) {
            console.error(`Failed to fetch puzzle: Server responded with status ${response.status}`);
            setPuzzle(defaultPuzzle);
          } else {
            const puzzleData = await response.json();
            setPuzzle(puzzleData);
            localStorage.setItem(storedPuzzleKey, JSON.stringify(puzzleData));
          }
        }
      } catch (error) {
        console.error('Error fetching or processing puzzle:', error);
        setPuzzle(defaultPuzzle);
      } finally {
        setLoading(false);
      }
    };

    fetchPuzzle();
  }, [selectedDate, navigate]);

  const handleDateChange = (newDate: Date | null) => {
    if (newDate && newDate <= new Date()) {
      setSelectedDate(newDate);
      const formattedDate = newDate.toISOString().split('T')[0];
      navigate(`/puzzle/${formattedDate}/${selectedDifficulty}`);
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: 'easy' | 'medium' | 'hard') => {
    setSelectedDifficulty(newValue);
    if (selectedDate) {
      const formattedDate = selectedDate.toISOString().split('T')[0];
      navigate(`/puzzle/${formattedDate}/${newValue}`);
    }
  };

  const getSolvedColor = (difficulty: 'easy' | 'medium' | 'hard') => {
    const solvedStatus = puzzle[difficulty].solved;
    return solvedStatus === 'yes' ? '#28FC06' : solvedStatus === 'no' ? '#FC0606' : '#464545';
  };

  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: 'Chess Puzzle', // You can customize this title
        text: 'Check out this chess puzzle I am solving!', // Custom message
        url: window.location.href // URL to share
      })
      .then(() => console.log('Successful share'))
      .catch((error) => console.error('Error sharing', error));
    } else {
      // Fallback for browsers that do not support the Web Share API
      navigator.clipboard.writeText(window.location.href)
        .then(() => alert('Puzzle URL copied to clipboard!'))
        .catch(err => console.error('Could not copy text: ', err));
    }
  };
  

  return (
    <Container>
      <Grid container direction="column" alignItems="left" justifyContent="center" spacing={1} sx={{ minHeight: '100vh' }}>
        <Grid item sx={{ marginBottom: '8px' }}>
          <Box display="flex" justifyContent="left" alignItems="left">
            <Button onClick={() => handleDateChange(new Date((selectedDate || new Date()).setDate((selectedDate || new Date()).getDate() - 1)))} disabled={!selectedDate || selectedDate <= new Date('2024-01-01')} sx={{ color: '#375643' }}>
              <ArrowBackIcon />
            </Button>
            <Typography variant="h6" sx={{ margin: '0 10px' }}>
              {selectedDate ? selectedDate.toLocaleDateString('en-GB') : ''}
            </Typography>
            <Button onClick={() => handleDateChange(new Date((selectedDate || new Date()).setDate((selectedDate || new Date()).getDate() + 1)))} disabled={!selectedDate || selectedDate >= new Date()} sx={{ color: '#375643' }}>
              <ArrowForwardIcon />
            </Button>
            <Button sx={{ color: '#375643' }} onClick={handleShare}>
          <ShareIcon />
        </Button>
          </Box>
        </Grid>
        <Grid item>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Select Date"
              value={selectedDate}
              onChange={handleDateChange}
              maxDate={new Date()}
              renderInput={(params: TextFieldProps) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item sx={{ marginBottom: '8px' }}>
          <Tabs value={selectedDifficulty} onChange={handleTabChange}>
            <Tab
              label="Easy"
              value="easy"
              sx={{
                fontWeight: selectedDifficulty === 'easy' ? 'bold' : 'normal',
                color: getSolvedColor('easy'),
                '&.Mui-selected': {
                  color: getSolvedColor('easy'),  // Ensures the color remains the same when selected
                }
              }}
            />
            <Tab
              label="Medium"
              value="medium"
              sx={{
                fontWeight: selectedDifficulty === 'medium' ? 'bold' : 'normal',
                color: getSolvedColor('medium'),
                '&.Mui-selected': {
                  color: getSolvedColor('medium'),
                }
              }}
            />
            <Tab
              label="Hard"
              value="hard"
              sx={{
                fontWeight: selectedDifficulty === 'hard' ? 'bold' : 'normal',
                color: getSolvedColor('hard'),
                '&.Mui-selected': {
                  color: getSolvedColor('hard'),
                }
              }}
            />
          </Tabs>

        </Grid>
        {!loading && (
          <Grid item sx={{ marginBottom: '8px' }}>
            <ChessboardComponent
              fen={puzzle[selectedDifficulty].fen}
              difficulty={selectedDifficulty}
              puzzleId={`puzzle-${(selectedDate || new Date()).toISOString().split('T')[0]}`}
              result={puzzle[selectedDifficulty].result}
              firstMove={puzzle[selectedDifficulty].first_move}
            />
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default PuzzlePage;
