//gamestatusmodal.tsx
import React from 'react';
import { Paper, Typography, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ReplayIcon from '@mui/icons-material/Replay';

interface GameStatusModalProps {
    status: string | null;
    onReplay: () => void;
    onClose: () => void;
    chessboardWidth: number;
}

const GameStatusModal: React.FC<GameStatusModalProps> = ({ status, onReplay, onClose, chessboardWidth }) => {
    if (!status) return null;

    const overlayStyle: React.CSSProperties = {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
    };

    const modalWidth = chessboardWidth * 0.76;
    const modalHeight = chessboardWidth * 0.5;

    const modalStyle: React.CSSProperties = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 10,
        width: `${modalWidth}px`,
        height: `${modalHeight}px`,
        padding: '20px',
        textAlign: 'center',
        backgroundColor: 'rgba(187, 187, 187, 0.5)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
    };

    const textStyle = {
        display: 'flex',  // Use flex to control inner alignment
        justifyContent: 'center',  // Center text horizontally
        alignItems: 'center',  // Center text vertically
        backgroundColor: '#375643',
        borderRadius: '8px',
        padding: '0 0',  // Reduced padding
        margin: '0 0',  // Maintain some margin for spacing
        width: '100%',
    };

    return (
        <div>
            <div style={overlayStyle} onClick={onClose}></div>
            <Paper style={modalStyle}>
                <IconButton
                    onClick={onClose}
                    style={{ position: 'absolute', right: 8, top: 8, color: 'gray' }}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>
                <Box sx={textStyle}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold'}}>{status}</Typography>
                </Box>
                <Box display="flex" justifyContent="center" mt={0}>
                    <IconButton onClick={onReplay} aria-label="replay" sx={{ color: 'inherit' }}>
                        <ReplayIcon />
                    </IconButton>
                </Box>
            </Paper>
        </div>
    );
};

export default GameStatusModal;
