import React from 'react';
import { Container, Typography, Paper } from '@mui/material';

const TermsOfService: React.FC = () => {
    return (
        <Container sx={{ padding: '20px' }}>
            <Paper sx={{ padding: '20px' }}>
                <Typography variant="h4" gutterBottom>
                    Terms of Service
                </Typography>
                <Typography variant="body1" paragraph>
                    Welcome to Chess Finish! By using this application, you agree to the following terms and conditions.
                </Typography>
                <Typography variant="body1" paragraph>
                    <strong>Usage:</strong> You are allowed to use Chess Finish for personal and non-commercial purposes. You agree not to misuse the application in any way that could harm the functionality or accessibility for other users.
                </Typography>
                <Typography variant="body1" paragraph>
                    <strong>Intellectual Property:</strong> All content and materials provided in Chess Finish are the property of the app developer. You are not allowed to use or reproduce any part of the application without permission.
                </Typography>
                <Typography variant="body1" paragraph>
                    <strong>Limitation of Liability:</strong> The developer of Chess Finish is not responsible for any damages or losses resulting from the use or inability to use the application.
                </Typography>
                <Typography variant="body1" paragraph>
                    <strong>Changes to the Terms:</strong> We reserve the right to update these terms at any time. Continued use of the application constitutes acceptance of any new terms.
                </Typography>
                <Typography variant="body1" paragraph>
                    If you have any questions or concerns about our terms of service, please contact us.
                </Typography>
            </Paper>
        </Container>
    );
};

export default TermsOfService;
