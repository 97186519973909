import React, { useState, useEffect, createContext } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Box, CssBaseline } from '@mui/material';
import App from './App';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfService from './TermsofService';
import PuzzlePage from './PuzzlePage';
import Footer from './Footer';
import Banner from './Banner';
import { GameStatusProvider } from './gameStatusContext';
import CookieConsent, { Cookies } from 'react-cookie-consent';

// Define the type for the consent context
interface ConsentContextType {
  consent: boolean;
  setConsent: React.Dispatch<React.SetStateAction<boolean>>;
}

// Create a context for managing consent state
const ConsentContext = createContext<ConsentContextType | null>(null);

const AppRouter: React.FC = () => {
  const [consent, setConsent] = useState(Cookies.get('myAwesomeCookieConsent') === 'true');

  useEffect(() => {
    if (Cookies.get('myAwesomeCookieConsent') === undefined) {
      setConsent(false);
    }
  }, []);

  useEffect(() => {
    const initializeAdSense = () => {
      if (typeof window.adsbygoogle !== 'undefined') {
        try {
          window.adsbygoogle.push({
            google_ad_client: 'ca-pub-5267973527217796',
            enable_page_level_ads: true,
            requestNonPersonalizedAds: !consent ? 1 : 0,
          });
          window.adsbygoogle.push({});
        } catch (error) {
          console.error('Error initializing AdSense:', error);
        }
      }
    };

    if (consent) {
      initializeAdSense();
    }

    // Ensure document is fully loaded before running any DOM manipulations
    document.addEventListener('DOMContentLoaded', (event) => {
      const element = document.querySelector(".some-class");
      if (element) {
        element.classList.add("new-class");
      }
    });

  }, [consent]);

  return (
    <GameStatusProvider>
      <ConsentContext.Provider value={{ consent, setConsent }}>
        <Router>
          <CssBaseline />
          <Box sx={{ width: '100vw', maxWidth: '100%', overflowX: 'hidden' }}>
            <Banner />
            <Box flexGrow={1}>
              <Routes>
                <Route path="/" element={<App />} />
                <Route path="/puzzle/:date/:difficulty" element={<PuzzlePage />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms-of-service" element={<TermsOfService />} />
              </Routes>
            </Box>
            <Footer />
          </Box>
          <CookieConsent
            location="bottom"
            buttonText="I understand"
            declineButtonText="I decline"
            cookieName="myAwesomeCookieConsent"
            style={{
              background: "#2B373B",
              color: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "10px", // Adjusted padding to be minimal
              zIndex: 1000,
            }}
            buttonStyle={{
              color: "#4e503b",
              fontSize: "13px",
              backgroundColor: "#f1d600",
              margin: "0 5px", // Adjusted margin for buttons
              padding: "5px 10px" // Adjusted padding for buttons
            }}
            declineButtonStyle={{
              color: "#4e503b",
              fontSize: "13px",
              backgroundColor: "#d60000",
              margin: "0 5px", // Adjusted margin for buttons
              padding: "5px 10px" // Adjusted padding for buttons
            }}
            enableDeclineButton
            expires={150}
            onAccept={() => {
              setConsent(true);
              Cookies.set('myAwesomeCookieConsent', 'true', { expires: 150 });
            }}
            onDecline={() => {
              setConsent(false);
              Cookies.remove('myAwesomeCookieConsent');
            }}
          >
            <span>This website uses cookies to enhance the user experience. By using this site, you agree to our use of cookies. Please read our <a href="/privacy-policy" style={{ color: "#f1d600" }}>Privacy Policy</a> for more information.</span>
          </CookieConsent>


        </Router>
      </ConsentContext.Provider>
    </GameStatusProvider>
  );
};

export default AppRouter;
