import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const App: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const today = new Date().toISOString().split('T')[0];
    navigate(`/puzzle/${today}/easy`);
  }, [navigate]);

  return <div>Loading...</div>;
};

export default App;
