import React, { createContext, useContext, useState } from 'react';

interface GameStatusContextValue {
    gameStatus: string;
    updateGameStatus: (status: string) => void;
}

const GameStatusContext = createContext<GameStatusContextValue | null>(null);

export const useGameStatus = () => {
    const context = useContext(GameStatusContext);
    if (!context) {
        throw new Error('useGameStatus must be used within a GameStatusProvider');
    }
    return context;
};


export const GameStatusProvider = ({ children }: { children: React.ReactNode }) => {
    const [gameStatus, setGameStatus] = useState<string>(''); // Ensure type is string as defined in the interface

    const updateGameStatus = (status: string) => {
        setGameStatus(status);
    };

    return (
        <GameStatusContext.Provider value={{ gameStatus, updateGameStatus }}>
            {children}
        </GameStatusContext.Provider>
    );
};
