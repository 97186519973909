// src/Banner.tsx
import React from 'react';
import { Box, Typography, Paper } from '@mui/material';

const Banner: React.FC = () => {
  return (
    <Paper elevation={3} sx={{ padding: '16px', textAlign: 'center', backgroundColor: '#f5f5f5', marginBottom: '16px' }}>
      <Typography variant="h5" component="h3" align="center" style={{ fontWeight: 'normal', marginBottom: '8px' }}>
        Chess Finish
      </Typography>
      <Typography variant="body1" align="center">
      Endgame Challenges: Learn endgames the hard way!!
      </Typography>
    </Paper>
  );
};

export default Banner;
