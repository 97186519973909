import React from 'react';
import { Container, Typography, Paper } from '@mui/material';

const PrivacyPolicy: React.FC = () => {
  return (
    <Container sx={{ padding: '20px' }}>
      <Paper sx={{ padding: '20px' }}>
        <Typography variant="h4" gutterBottom>
          Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph>
          This privacy policy outlines how our application, Chess Finish, handles user data.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>No Personal Data Collection:</strong> Our application does not collect, store, or process any personal data. We do not require any sign-in, email addresses, or any other personal information to use the application.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Third-Party Services:</strong> We do not use any third-party services that collect personal data through our application.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Cookies:</strong> Our application does not use cookies or similar tracking technologies.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Google AdSense:</strong> Although our application itself does not use cookies, we do display advertisements through Google AdSense. Google AdSense uses cookies to serve ads based on a user's prior visits to our website and other websites on the internet. Google's use of advertising cookies enables it and its partners to serve ads to users based on their visit to our site and/or other sites on the internet. If you do not consent to the use of cookies, we will serve non-personalized ads that are not based on your prior browsing behavior. You may opt out of personalized advertising by visiting the <a href="https://www.google.com/settings/ads" target="_blank" rel="noopener noreferrer">Google Ads Settings</a>. Alternatively, you can opt out of a third-party vendor's use of cookies for personalized advertising by visiting <a href="http://www.aboutads.info/choices/" target="_blank" rel="noopener noreferrer">www.aboutads.info</a>.
        </Typography>
      </Paper>
    </Container>
  );
};

export default PrivacyPolicy;
