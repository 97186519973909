import React from 'react';
import { Typography, Button, Paper, Box } from '@mui/material';

interface Move {
    moveNumber: number;
    white: string;
    black?: string;
}

interface MovesSectionProps {
    moveList: Move[];
    copyMovesToClipboard: () => void;
    copyButtonText: string;
    chessboardWidth: number;
}

const MovesSection: React.FC<MovesSectionProps> = ({ moveList, copyMovesToClipboard, copyButtonText, chessboardWidth }) => {
    return (
        <Paper elevation={3} sx={{ padding: 2, margin: 0, maxHeight: 400, width: chessboardWidth, overflowY: 'auto' }}>
            <Typography variant="h6" gutterBottom>
                Moves:
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {moveList.map((movePair, index) => (
                    <Box key={index} sx={{ padding: 1 }}>
                        <Typography variant="body1">
                            {movePair.moveNumber}. {movePair.white} {movePair.black ? movePair.black : ''}
                        </Typography>
                    </Box>
                ))}
            </Box>
            <Button variant="contained" color="primary" onClick={copyMovesToClipboard} sx={{ marginTop: 2 , backgroundColor:'#375643' }}>
                {copyButtonText}
            </Button>
        </Paper>
    );
};

export default MovesSection;
