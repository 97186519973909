//chessboard.tsx
import React, { useEffect, useState, useRef } from 'react';
import { Chess, Square, Move as ChessMove } from 'chess.js';
import { Chessboard } from 'react-chessboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faRedo } from '@fortawesome/free-solid-svg-icons';
import GameStatusModal from './gameStatusModal';
import MovesSection from './movesSection';
import { Button, useMediaQuery, useTheme, Paper } from '@mui/material';
import Unstable_Grid2 from '@mui/material/Unstable_Grid2';
import './chessboard.css';
import { useGameStatus } from './gameStatusContext';

type GameOutcome = "YOU WIN!" | "GAME OVER"; // Define this type


interface ChessboardComponentProps {
  fen: string;
  difficulty: 'easy' | 'medium' | 'hard';
  puzzleId: string;
  result: string;
  firstMove: string;
}

interface Move {
  moveNumber: number;
  white: string;
  black?: string;
}

const ChessboardComponent: React.FC<ChessboardComponentProps> = ({ fen, difficulty, puzzleId, result, firstMove }) => {
  const { gameStatus, updateGameStatus } = useGameStatus();
  const [chess, setChess] = useState(new Chess(fen));
  const [currentFen, setCurrentFen] = useState(fen);
  const [moveList, setMoveList] = useState<Move[]>([]);
  const [orientation, setOrientation] = useState<'white' | 'black'>('white');
  const movesRef = useRef<HTMLDivElement>(null);
  //const [gameStatus, setGameStatus] = useState<string>('');
  const [copyButtonText, setCopyButtonText] = useState('Copy');
  const [lastMove, setLastMove] = useState<{ from: Square, to: Square } | null>(null);
  const [opponentMoving, setOpponentMoving] = useState(false);
  const [selectedSquare, setSelectedSquare] = useState<Square | null>(null);
  const [validMoves, setValidMoves] = useState<Square[]>([]);
  const [promotionMove, setPromotionMove] = useState<{ from: Square, to: Square } | null>(null);
  const [promotionPending, setPromotionPending] = useState(false);
  const userColor = fen ? (fen.split(' ')[1] === 'w' ? 'black' : 'white') : 'white'; // Default to white if undefined
  const stockfishWorker = useRef<Worker | null>(null);
  const [showPromotionDialog, setShowPromotionDialog] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  const [chessboardWidth, setChessboardWidth] = useState(() => {
    if (isMd) return 400;
    if (isSm) return 360;
    if (isXs) return 320;
    return 400;
  });

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      const boardWidth = Math.min(screenWidth - 20, 400);
      setChessboardWidth(boardWidth);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const chessInstance = new Chess(fen);
    const userColor = fen?.split(' ')[1] === 'w' ? 'black' : 'white';

    setChess(chessInstance);
    setCurrentFen(fen);
    updateGameStatus('');
    setOrientation(userColor);
    updateMoveList(chessInstance);

    const handleComputerFirstMove = async () => {
      if ((userColor === 'white' && chessInstance.turn() === 'b') ||
        (userColor === 'black' && chessInstance.turn() === 'w')) {
        setOpponentMoving(true);
        await new Promise(r => setTimeout(r, 1000)); // Wait for state to stabilize if needed
        const move = chessInstance.move(firstMove);
        if (move) {
          setLastMove({ from: move.from, to: move.to });
          setCurrentFen(chessInstance.fen());
          updateMoveList(chessInstance);
          checkGameStatus(chess, result, difficulty, puzzleId);
        } else {
          console.error('Invalid move:', firstMove);
        }
        setOpponentMoving(false);
      }
    };

    handleComputerFirstMove();
  }, [fen, firstMove, difficulty, puzzleId]);


  const checkGameStatus = (chessInstance: Chess, result: string, difficulty: string, puzzleId: string) => {
    let gameOutcome = '';
    let actualResult = '';
    if (chessInstance.isCheckmate()) {
      const winner = chessInstance.turn() === 'w' ? 'black' : 'white';
      gameOutcome = 'win';
      actualResult = (winner === userColor) ? 'win' : 'lose';
    } else if (chessInstance.isDraw() || chessInstance.isStalemate() || chessInstance.isThreefoldRepetition() || chessInstance.isInsufficientMaterial()) {
      gameOutcome = 'draw';
      actualResult = 'draw';
    }

    // Only update status if it changes
    let newStatus = '';
    if (actualResult === result.toLowerCase()) {
      newStatus = 'YOU WIN!';
    } else if (gameOutcome) {
      newStatus = 'GAME OVER';
    }

    // Check if status really needs updating
    if (gameStatus !== newStatus && newStatus !== '') {
      updateGameStatus(newStatus);
      updateLocalStorage(difficulty, actualResult === result.toLowerCase() ? 'yes' : 'no', puzzleId);
      setChess(new Chess(fen));
    }
  };

  const updateLocalStorage = (
    difficulty: string,
    solvedStatus: string,
    puzzleId: string
  ): void => {
    const key = puzzleId;
    const storedData = localStorage.getItem(key);
    let puzzleData = storedData ? JSON.parse(storedData) : { easy: {}, medium: {}, hard: {} };

    if (!puzzleData[difficulty].solved) {
      // Initial set if not present
      puzzleData[difficulty].solved = solvedStatus;
    } else if (puzzleData[difficulty].solved !== 'yes') {
      // Update only if not already 'yes'
      if (solvedStatus === 'yes' || (puzzleData[difficulty].solved === 'no' && solvedStatus === 'no')) {
        puzzleData[difficulty].solved = solvedStatus;
      }
    }

    localStorage.setItem(key, JSON.stringify(puzzleData));
  };


  const updateMoveList = (chessInstance: Chess) => {
    const history = chessInstance.history({ verbose: true });
    const newMoveList: Move[] = [];
    let moveNumber = 1;
    if (fen?.split(' ')[1] === 'b' && history.length > 0) {
      newMoveList.push({ moveNumber: moveNumber++, white: '...', black: history[0].san });
    }

    for (let i = fen?.split(' ')[1] === 'b' ? 1 : 0; i < history.length; i += 2) {
      const whiteMove = history[i] ? history[i].san : '...';
      const blackMove = history[i + 1] ? history[i + 1].san : '...';
      newMoveList.push({ moveNumber: moveNumber++, white: whiteMove, black: blackMove });
    }

    setMoveList(newMoveList);
  };


  const handleSquareClick = (square: Square) => {
    if (selectedSquare) {
      const piece = chess.get(selectedSquare);
      const isPotentialPromotion = piece?.type === 'p' && (
        (piece.color === 'w' && square[1] === '8') ||
        (piece.color === 'b' && square[1] === '1')
      );

      if (isPotentialPromotion) {
        const validMoves = chess.moves({ square: selectedSquare, verbose: true });
        const validPromotionMove = validMoves.some(m => m.to === square && m.promotion);
        if (validPromotionMove) {
          setPromotionMove({ from: selectedSquare, to: square });
          setPromotionPending(true);
          setShowPromotionDialog(true);
          return;
        }
      }

      const moveMade = handleMove(selectedSquare, square);
      if (moveMade) {
        setSelectedSquare(null);
      } else {
        setSelectedSquare(square);
      }
    } else {
      setSelectedSquare(square);
    }
  };

  const handleMove = (sourceSquare: Square, targetSquare: Square, promotion?: string): boolean => {
    try {
      const move: any = { from: sourceSquare, to: targetSquare };
      if (promotion) {
        move.promotion = promotion;

      }

      const moveResult = chess.move(move);
      if (moveResult === null) {
        console.error("Failed move:", { from: sourceSquare, to: targetSquare, promotion });
        return false;
      }
      setLastMove({ from: sourceSquare, to: targetSquare });
      setCurrentFen(chess.fen());
      updateMoveList(chess);
      checkGameStatus(chess, result, difficulty, puzzleId);
      setPromotionMove(null);

      if (chess.turn() !== userColor.charAt(0)) {
        setOpponentMoving(true);
        makeComputerMove();
      }
      return true;
    } catch (error) {
      console.error("An error occurred during the move attempt:", error instanceof Error ? error.message : "Unknown error");
      setCurrentFen(chess.fen());
      return false;
    }
  };


  const makeComputerMove = () => {
    setTimeout(() => {  // Adding a 500 ms delay before making the move
      const fen = chess.fen();
      if (stockfishWorker.current) {
        stockfishWorker.current.postMessage(`position fen ${fen}`);
        stockfishWorker.current.postMessage("go depth 18");
      } else {
        console.error("Stockfish worker is not initialized");
      }
    }, 500);
  };

  useEffect(() => {
    if (!stockfishWorker.current) {
      stockfishWorker.current = new Worker('/stockfish-nnue-16.js');

      stockfishWorker.current.onmessage = (event) => {
        const message = event.data;

        if (message.startsWith('bestmove')) {
          const bestMove = message.split(' ')[1];
          const move = chess.move(bestMove);
          if (move) {
            setLastMove({ from: move.from, to: move.to });
            setCurrentFen(chess.fen());
            updateMoveList(chess);
            checkGameStatus(chess, result, difficulty, puzzleId);
            setOpponentMoving(false);
          }
        }
      };
    }

    return () => {
      stockfishWorker.current?.terminate();
      stockfishWorker.current = null;
    };
  }, [chess]);

  const moveBackward = () => {
    chess.undo();
    setCurrentFen(chess.fen());
    updateMoveList(chess);
    checkGameStatus(chess, result, difficulty, puzzleId);
    setPromotionMove(null);
    setSelectedSquare(null);
    setTimeout(() => {
      chess.undo();
      setCurrentFen(chess.fen());
      updateMoveList(chess);
      checkGameStatus(chess, result, difficulty, puzzleId);
      setSelectedSquare(null);
    }, 400);
  };


  const resetGame = () => {
    const chessInstance = new Chess(fen);
    setChess(chessInstance);
    setCurrentFen(fen);
    setMoveList([]);
    updateGameStatus('');
    setLastMove(null);
    setValidMoves([]);
    setSelectedSquare(null);
    setPromotionMove(null);
    setChess(chessInstance);

    if ((userColor === 'white' && chessInstance.turn() === 'b') || (userColor === 'black' && chessInstance.turn() === 'w')) {
      setOpponentMoving(true);
      setTimeout(() => {
        const move = chessInstance.move(firstMove);
        if (move) {
          setLastMove({ from: move.from, to: move.to });
          setCurrentFen(chessInstance.fen());
          updateMoveList(chessInstance);
          checkGameStatus(chess, result, difficulty, puzzleId);
        } else {
          console.error('Invalid move:', firstMove);
        }
        setOpponentMoving(false);
      }, 400);
    }
  };

  const copyMovesToClipboard = () => {
    const movesText = moveList.map(movePair => `${movePair.moveNumber}. ${movePair.white} ${movePair.black || ''}`).join(' ');
    navigator.clipboard.writeText(movesText).then(() => {
      setCopyButtonText('Copied...');
      setTimeout(() => {
        setCopyButtonText('Copy');
      }, 500);
    }, (err) => {
      console.error('Could not copy text: ', err);
    });
  };


  const squareStyles = {
    ...(lastMove ? {
      [lastMove.from]: {
        backgroundColor: 'rgba(255, 255, 0, 0.4)'
      },
      [lastMove.to]: {
        backgroundColor: 'rgba(255, 255, 0, 0.4)'
      }
    } : {}),
    ...(selectedSquare ? {
      [selectedSquare]: {
        backgroundColor: 'rgba(255, 255, 0, 0.4)'
      }
    } : {}),
    ...validMoves.reduce((styles, square) => {
      styles[square] = {
        backgroundColor: 'grey',
        borderRadius: '90%'
      };
      return styles;
    }, {} as { [key: string]: React.CSSProperties })
  };


  const handlePieceDrop = (sourceSquare: Square, targetSquare: Square): boolean => {
    const piece = chess.get(sourceSquare);
    const isPromotion = piece?.type === 'p' && (
      (piece.color === 'w' && targetSquare[1] === '8') ||
      (piece.color === 'b' && targetSquare[1] === '1')
    );

    if (isPromotion) {
      setPromotionMove({ from: sourceSquare, to: targetSquare });
      setPromotionPending(true);
      setShowPromotionDialog(false);
      return false;
    }

    const moveMade = handleMove(sourceSquare, targetSquare);
    if (moveMade && chess.turn() !== userColor.charAt(0)) {
      setOpponentMoving(true);
      makeComputerMove();
    }
    return moveMade;
  };

  const handlePromotionPieceSelect = (piece: string): boolean => {

    if (!promotionPending) {
      console.error("No promotion is pending, but promotion piece selection was triggered.");
      return false;
    }
    if (!piece || !promotionMove) {
      console.error("Promotion piece or move details are missing.", { piece, promotionMove });
      return false;
    }


    const pieceMap: { [key: string]: string } = {
      'wQ': 'q', 'bQ': 'q',
      'wR': 'r', 'bR': 'r',
      'wB': 'b', 'bB': 'b',
      'wN': 'n', 'bN': 'n',
    };


    const chessJsPromotion = pieceMap[piece] || piece;

    const moveMade = handleMove(promotionMove.from, promotionMove.to, chessJsPromotion);
    if (moveMade) {

      setPromotionMove(null);
      setPromotionPending(false);
      setShowPromotionDialog(false);
      if (chess.turn() !== userColor.charAt(0)) {
        setOpponentMoving(true);
        makeComputerMove();
      }
    } else {

      console.error('Promotion move failed:', { from: promotionMove.from, to: promotionMove.to, promotion: chessJsPromotion });
      setPromotionMove(null);
      setPromotionPending(false);
      setShowPromotionDialog(false);
    }
    return moveMade;
  };

  useEffect(() => {
    console.log(`${gameStatus}`);
  }, [gameStatus]);



  return (
    <div>
      <Unstable_Grid2 container spacing={0} className="chess-container" sx={{ height: '100%', width: '100%', position: 'relative' }}>
      </Unstable_Grid2>
      <Unstable_Grid2 component="div" xs={12} lg={7} container direction="column" spacing={1} alignItems="left" >
        <Paper sx={{ position: 'relative', width: chessboardWidth }}>
          <Chessboard
            boardWidth={chessboardWidth}
            position={currentFen}
            boardOrientation={orientation}
            onPieceDrop={handlePieceDrop}
            onSquareClick={handleSquareClick}
            customLightSquareStyle={{ backgroundColor: '#7d8d7c' }}
            customDarkSquareStyle={{ backgroundColor: '#375643' }}
            animationDuration={600}
            customSquareStyles={squareStyles}
            showPromotionDialog={showPromotionDialog}
            promotionToSquare={promotionMove ? promotionMove.to : null}
            onPromotionCheck={(sourceSquare: Square, targetSquare: Square, piece: string): boolean => {
              const pieceObj = chess.get(sourceSquare);
              const isPotentialPromotion = pieceObj?.type === 'p' && (
                (pieceObj.color === 'w' && targetSquare[1] === '8') ||
                (pieceObj.color === 'b' && targetSquare[1] === '1')
              );

              if (isPotentialPromotion) {
                // Fetch valid moves to verify if the promotion is legal
                const validMoves = chess.moves({ square: sourceSquare, verbose: true });
                const validPromotionMove = validMoves.some(m => m.to === targetSquare && m.promotion);

                if (validPromotionMove) {
                  setPromotionMove({ from: sourceSquare, to: targetSquare });
                  setPromotionPending(true);
                  setShowPromotionDialog(true);
                } else {
                  // If the move is not a valid promotion move, don't trigger the promotion dialog
                  return false;
                }
              }
              return isPotentialPromotion;
            }}

            onPromotionPieceSelect={(piece) => {
              if (!piece) {
                return false;
              }
              return handlePromotionPieceSelect(piece);
            }}
          />
          {gameStatus && (
            <GameStatusModal
              status={gameStatus}
              onReplay={resetGame}
              onClose={() => updateGameStatus('')}
              chessboardWidth={chessboardWidth}
            />
          )}
        </Paper>
        <Unstable_Grid2>
          <Button startIcon={<FontAwesomeIcon icon={faArrowLeft} />} onClick={moveBackward} sx={{ color: '#375643' }}>
          </Button>
          <Button startIcon={<FontAwesomeIcon icon={faRedo} />} onClick={resetGame} sx={{ color: '#375643' }}>
          </Button>
          <Paper sx={{ padding: '8px', flexGrow: 1, textAlign: 'center', width: chessboardWidth }}>
            <strong>{userColor.charAt(0).toUpperCase() + userColor.slice(1)} to {result}</strong>
          </Paper>
        </Unstable_Grid2>
      </Unstable_Grid2>
      <Unstable_Grid2 xs={12} lg={5} component="div">
        <MovesSection
          moveList={moveList}
          copyMovesToClipboard={copyMovesToClipboard}
          copyButtonText={copyButtonText}
          chessboardWidth={chessboardWidth}
        />
      </Unstable_Grid2>
    </div>
  );
};

export default ChessboardComponent;
